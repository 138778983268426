jQuery(document).ready(function ($) {
  if ($('.p-map')[0]) {
    //set your google maps parameters
    var latitude = 43.7721,
      longitude = 142.322912,
      map_zoom = 15;

    //google map custom marker icon - .png fallback for IE11
    // var is_internetExplorer11= navigator.userAgent.toLowerCase().indexOf('trident') > -1;
    // var marker_url = ( is_internetExplorer11 ) ? 'images/map_location.png' : 'images/map_location.svg';
    var marker_image = {
      url: '/cms/wp-content/themes/souju/assets/images/map_location.png',
      scaledSize: new google.maps.Size(257, 60),
    };

    //define the basic color of your map, plus a value for saturation and brightness
    var main_color = '#3C5660',
      saturation_value = -50,
      brightness_value = 10;

    //we define here the style of the map
    var style = [
      {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#444444',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            color: '#f2f2f2',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.business',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '#b4d4e1',
          },
          {
            visibility: 'on',
          },
        ],
      },
    ];

    //set google map options
    var map_options = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: map_zoom,
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      styles: style,
    };
    //inizialize the map
    var map = new google.maps.Map(
      document.getElementById('p-map__container'),
      map_options,
    );
    //add a custom marker to the map
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      map: map,
      visible: true,
      icon: marker_image,
    });

    //add custom buttons for the zoom-in/zoom-out on the map
    function CustomZoomControl(controlDiv, map) {
      //grap the zoom elements from the DOM and insert them in the map
      var controlUIzoomIn = document.getElementById('p-map__zoom--in'),
        controlUIzoomOut = document.getElementById('p-map__zoom--out');
      controlDiv.appendChild(controlUIzoomIn);
      controlDiv.appendChild(controlUIzoomOut);

      // Setup the click event listeners and zoom-in or out according to the clicked element
      google.maps.event.addDomListener(controlUIzoomIn, 'click', function () {
        map.setZoom(map.getZoom() + 1);
      });
      google.maps.event.addDomListener(controlUIzoomOut, 'click', function () {
        map.setZoom(map.getZoom() - 1);
      });
    }

    var zoomControlDiv = document.createElement('div');
    zoomControlDiv.setAttribute('class', 'p-map__zoom');
    var zoomControl = new CustomZoomControl(zoomControlDiv, map);

    //insert the zoom div on the top left of the map
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv);
  }
});
