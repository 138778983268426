import './vendor/googlemap.acf.js';
import './vendor/googlemap.js';
import './vendor/jquery.clever-infinite-scroll.js';
import './vendor/jquery.fancybox.js';
import './vendor/jquery.infinitescroll.js';
import './vendor/jquery.particleground.js';
import './vendor/jquery.tile.js';
import './vendor/newWaterfall.js';
import 'jquery-inview';
import 'vegas';
import Swiper from 'swiper';
import isMobile from 'ismobilejs';

setTimeout(function () {
  $(() => {
    $('.l-header').vegas({
      overlay: false,
      delay: 10000,
      timer: false,
      slidesToKeep: 1,
      transition: 'fade2',
      transitionDuration: 8000,
      animation: 'random',
      animationDuration: 10000,
      slides: [
        {
          src: '/cms/wp-content/themes/souju/assets/images/header_slide_1.webp',
        },
        {
          src: '/cms/wp-content/themes/souju/assets/images/header_slide_2.webp',
        },
        {
          src: '/cms/wp-content/themes/souju/assets/images/header_slide_3.webp',
        },
        {
          src: '/cms/wp-content/themes/souju/assets/images/header_slide_4.webp',
        },
        {
          src: '/cms/wp-content/themes/souju/assets/images/header_slide_5.webp',
        },
      ],
    });
  });
  $('.p-header__logo').fadeOut(3000);
}, 3000);

// Objects that change when scrolled
$(() => {
  if ($('.p-header__logo')[0]) {
    const logoTop = $('.p-header__logo').offset().top;
    $(window).scroll(function () {
      const scrollTop = $(this).scrollTop();
      if (scrollTop > logoTop) {
        $('.p-header__title').fadeOut('slow');
        $('.p-header__scroll').fadeOut('slow');
      } else {
        $('.p-header__title').fadeIn('slow');
        $('.p-header__scroll').fadeIn('slow');
      }
    });
  }
});

// Slide for works of thumbnail
$(() => {
  const mySwiper = new Swiper('.swiper-container', {
    effect: 'slide',
    loop: true,
    breakpoints: {
      320: {
        slidesperview: 1,
      },
      480: {
        slidesperview: 2,
      },
      640: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 1,
      },
    },
    speed: 1000,
    autoplay: 9000,
    autoplayDisableOnInteraction: false,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
  });

  $('.p-list-work__description').hover(
    function () {
      $(this).addClass('p-list-work__description--hover');
    },
    function () {
      $(this).removeClass('p-list-work__description--hover');
    },
  );
});

// work
$(() => {
  $('.p-list-work__description').tile();
});

// Smooth scrolling to links
const TIME = 1000;
const BUFFER = 0;
$(() => {
  $('.v-drawer-nav a').on('click', function (e) {
    const target = $(this.hash);
    if (!target.length) return;
    const zm = parseInt($('body').css('zoom')) || 1;
    const targetY = $(target).offset().top - BUFFER;
    $('html,body')
      .stop()
      .animate({ scrollTop: targetY * zm }, TIME, 'swing', function () {
        $('.v-drawer').drawer('close');
      });
    window.history.pushState(null, null, this.hash);
    return false;
  });
});

// Scroll smoothly to the top of the page
$(() => {
  const topBtn = $('.p-footer__pagetop');
  topBtn.click(function () {
    $('body,html').animate(
      {
        scrollTop: 0,
      },
      1000,
    );
    return false;
  });
});

$('.inview').on('inview', function (event, isInView) {
  if (isInView) {
    $(this).stop().addClass('is-show');
  }
  // } else {
  //   $(this).stop().removeClass('is-show');
  // }
});

// Drawer for global navigation
$(() => {
  $('.v-drawer').drawer({
    class: {
      nav: 'v-drawer-nav',
      sidebar: 'v-drawer-sidebar',
      toggle: 'v-drawer-toggle',
      overlay: 'v-drawer-overlay',
      open: 'v-drawer-open',
      close: 'v-drawer-close',
    },
  });

  $('.v-drawer-hamburger').animate({ left: -240 }, 0);

  $(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $('.v-drawer-hamburger').animate({ left: 0 }, 0);
    } else {
      $('.v-drawer-hamburger').animate({ left: -240 }, 0);
    }
  });
});

// Particle background for information
if (!isMobile.any) {
  $('.s-information').particleground({
    dotColor: '#FFFFFF',
    lineColor: '#FFFFFF',
  });
}

// Waterfall for information
$(() => {
  $('.p-cards').NewWaterfall({
    width: 500,
    delay: 60,
    repeatShow: true,
  });
});

// Infinite scroll for information
$(() => {
  $('.p-cards').infinitescroll({
    navSelector: '.s-information__nav',
    nextSelector: '.next',
    itemSelector: '.p-card-information',
  });
});

// Filtering for index of works
$(() => {
  'use strict';

  const $filters = $('.s-works__filter [data-filter]');
  const $boxes = $('.p-section__body--works [data-category]');

  $filters.on('click', function (e) {
    e.preventDefault();

    const $this = $(this);

    $filters.removeClass('active');
    $this.addClass('active');

    const $filterColor = $this.attr('data-filter');

    if ($filterColor == 'all') {
      $boxes
        .removeClass('is-animated')
        .fadeOut()
        .promise()
        .done(function () {
          $boxes.addClass('is-animated').fadeIn();
        });
    } else {
      $boxes
        .removeClass('is-animated')
        .fadeOut()
        .promise()
        .done(function () {
          $boxes
            .filter('[data-category = "' + $filterColor + '"]')
            .addClass('is-animated')
            .fadeIn();
        });
    }
  });
});

// Infinite scroll for show of works
$(() => {
  const windowHeight = $(window).height();
  $('.p-section__inner--works-show').cleverInfiniteScroll({
    contentsWrapperSelector: '.p-section__inner--works-show',
    contentSelector: '.s-works__box',
    nextSelector: '.previous',
    loadImage: '/cms/wp-content/themes/souju/assets/images/loading.gif',
    offset: windowHeight,
  });
});

// Lightbox for show of works
$(() => {
  $('[data-fancybox]').fancybox();
});
